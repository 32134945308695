import eventsHandler from '@/services/common/eventsHandler.js'

class unitOfMeasureForm extends eventsHandler {
  constructor() {
    super()
    this._unitOfMeasure = {}
    this._valid = false
  }

  get unitOfMeasure() {
    const unitOfMeasure = {
      ...this._unitOfMeasure
    }
    return unitOfMeasure
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._unitOfMeasure.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetUnitOfMeasure() {
    this._unitOfMeasure = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {unitOfMeasure: {}})
  }

  updateField(key, value) {
    this._unitOfMeasure[key] = value
    this._executeCallbacksOf('update', {unitOfMeasure: this._unitOfMeasure})
    this._executeCallbacksOf('update-' + key, {key: this._unitOfMeasure[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(unitOfMeasure) {
    this._unitOfMeasure = unitOfMeasure
    this._executeCallbacksOf('update', {unitOfMeasure: this._unitOfMeasure})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new unitOfMeasureForm()